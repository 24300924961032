    @import '../../../styles/Variables.scss';

    .container {
      width: 300;
      .MuiDrawer-paper {
        width: 300;
        color: $dark;
        background-color: $sideNavBGcolor;
        border-color: $borderColor;
      }
    }

    .userName {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 4px;
    }
    
    .searchInput {
      .MuiOutlinedInput-root {
       fieldset {
          border-color: #888; // Change inactive border color here
        }
        &:hover fieldset {
          border-color: #888; // Change hover border color here
        }
        &.Mui-focused fieldset {
          color: #000;
          border-color: white; // Change active border color here
        }
      }
      .MuiInputLabel-root {
        color: #000; // Change the placeholder color here
      }
       .MuiOutlinedInput-input {
        color:$dark; // Change the font color here
      }
    }

  .listItem {
    border-radius: 5px;
    padding: 0 !important;
    font-size: 0.9rem;
  }