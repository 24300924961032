@import "../../../styles/Variables.scss";

.title {
    margin-left: .6rem !important;
    color: $dark;
    font-size: $font-size-16px !important;
    font-weight: 500 !important;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: space-between !important;
  min-height:55px !important
}