@import "../../../styles/Variables.scss";

.toolbar {
  background-color: $white;
//   @media (max-width: 800px) {
    padding: 0.7rem 0 0 0 !important;
    // padding-top: 2rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: baseline;
    flex: 1;
    height: 100vh !important;
    text-align: center;
    display: flex;
    align-items: center;
//   }
}

.menuIcon{
    font-size: 2rem !important;
}

.sideLogout {
    position: absolute !important;
    padding: 8px !important;
    bottom: 1rem !important;
    left: 1rem !important;
}

.logoutButton {
    color: $red;
    cursor: pointer;
    border-radius: "50%";
}