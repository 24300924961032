.container {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px 5px 5px 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: visible;
    margin: 9px;
}

.header {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 10px 5px 5px 10px;
    padding: 4px;
    
}

.inputContainer {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    margin : 3px
}

.inputField {
    border: none;
    padding: 8px;
    width: 150px;
    height: 30px;
    outline: none;
    border-radius: 10px 0px 0px 10px;
}

.clearButton {
    background: #fff;
    border: none;
    border-bottom: 2px solid transparent;
    height: 100%;
    border-radius: 0 10px 10px 0;
    align-self: end;
}

.matchCase {
    background: transparent;
    border: none;
    height: 100%;
    padding: 5px;
    align-self: end;
    cursor: pointer;
}

.matchCase:hover{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.matchWholeWord{
    background: transparent;
	border: none;
	height: 100%;
    padding: 5px;
	align-self: end;
	cursor: pointer
}
.matchWholeWord:hover{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.toolTipContainer { padding: 0; align-self: center}

.pageNumberContainer {  align-self: center ; text-align: center; padding: 0 5px; }