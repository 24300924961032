@import "../../styles/Variables.scss";

.navContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1
}
.logoutContainer {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 1em;
    padding-top: 10px;
  };

.logoutButton {
    color: $red;
    cursor: pointer;
    border-radius: "50%";
}

.customAppbar {
    background-color: $white !important;
    width: 4.5rem !important; // minimisedDrawer
    position: absolute;
    margin-left: 0 !important;
    top: 0;
    bottom: 0;
    left: 0 !important;
    
    color: $dark;
    // margin: 0 auto;
      button {
        margin-right: 0;
        padding: 0;
        color: $dark;
      }
}

.drawerContainer {
    display: flex;
    align-items: center;
}

.userName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $dark;
    font-size: $font-size-12px !important;
}

.listContainer {
  height: 92vh;
  border: 1px solid red;


}
.list {
  height: 80%;
  border: 1px solid green;
}