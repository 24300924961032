@import './styles/Variables.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}
.css-ttn1xo {
	padding: 0 !important;
}
.css-1pn59pd {
	padding: 0 !important;
}
.css-hyum1k-MuiToolbar-root {
	padding-left: 16px !important;
	padding-right: 16px !important;
}

.settingsDiv,
.simulationDiv,
.statisticsDiv {
	width: 100%;
	height: 100%;
}

::placeholder {
	color: $gray-text !important;
}

.Resizer {
	background: #000;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid rgba(0, 0, 0, 0.5);
	border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, 0.5);
	border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
	cursor: not-allowed;
}
.Resizer.disabled:hover {
	border-color: transparent;
}
.pdf-object {
	border: none !important;
	outline: none !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: $dark !important;
}

.MuiFormLabel-root.Mui-focused {
	color: $dark !important;
}
.rpv-core__icon {
	vertical-align: middle;
}

.login-screen {
	height: 100vh;
	overflow: hidden;
	display: grid;

	@media (min-width: 1024px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	grid-template-columns: repeat(1, minmax(0, 1fr));

	.image-container {
		height: 100%;
		position: relative;
		background: url(https://images.pexels.com/photos/3780104/pexels-photo-3780104.png);
		filter: grayscale(100%);
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.text-container {
		padding: 3rem;
		z-index: 2;
		display: flex;
		align-items: center; /* Align items vertically */
		position: relative;
	}

	.text-container::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: -1;
	}

	h1,
	p {
		color: white;
		text-align: left;
	}

	.logo {
		width: auto; /* Adjust according to your needs */
		height: 100px; /* Adjust according to your needs */
		margin-right: 20px; /* Adjust margin between logo and text */
	}

	.form-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 100%;
		margin: auto;

		text-align: center;

		.header {
			display: flex;
			align-items: center;
			margin-bottom: 1.25rem;

			.logo {
				width: 3rem;
				height: auto;
				margin-bottom: 1.25rem;
			}

			.text {
				margin-bottom: 1.25rem;
				text-align: center;
				font-size: 40px;
				color: black;
				margin-left: 1.25rem;
				font-weight: bold;
			}
		}

		.form {
			width: 100%;
			max-width: 30rem;

			padding: 3rem;

			background-color: white;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
		}
	}
}
