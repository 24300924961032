// SuccessPayment.module.scss
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #e8e8e8;
    text-align: center;
}
.paymentContent {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    font-weight: normal;
}
.icons {
    transform: scale(1.5);
}
.button {
    margin-top: 16px; 
}
